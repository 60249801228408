import React, { useEffect, useRef } from 'react'
import './investorWhyInvest.css'
import InvestorWhyImage5 from "../../images/rectangle-147@2x.png"
import InvestorWhyImage6 from "../../images/vector-06.svg"
import InvestorWhyImage7 from "../../images/vector-61.svg"
import InvestorWhyImage8 from "../../images/group-7.svg"
import InvestorWhyImage9 from "../../images/mask-group1@2x.png"
import InvestorWhyImage10 from "../../images/group-8.svg"
import InvestorWhyImage11 from "../../images/mask-groupp@2x.png"
import Aos from "aos";
import "aos/dist/aos.css";


export default function InvestorWhyInvest() {

  
  const incrementRef = useRef(null);
  const incrementRef1 = useRef(null);
  const imageCircleRef1 = useRef(null);
  const imageCircleRef = useRef(null);


  useEffect(() => {
    const imageObserver1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          imageCircleRef.current.style.animation = 'rotateCircle 4s linear forwards';
        } else {
          imageCircleRef.current.style.animation = 'none';
          void imageCircleRef.current.offsetWidth;
        }
      });
    });
    if (imageCircleRef.current) {
      imageObserver1.observe(imageCircleRef.current);
    }



    const imageObserver2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          imageCircleRef1.current.style.animation = 'rotateCircle 4s linear forwards';
        } else {
          imageCircleRef1.current.style.animation = 'none';
          void imageCircleRef1.current.offsetWidth;
        }
      });
    });
    if (imageCircleRef1.current) {
      imageObserver2.observe(imageCircleRef1.current);
    }

    

    const incrementObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          incrementNumber();
          incrementObserver.unobserve(incrementRef.current);
        }
      });
    });
    if (incrementRef.current) {
      incrementObserver.observe(incrementRef.current);
    }
   
    const incrementNumber = () => {
      let start = 0;
      const end = 16.27; 
      const duration = 2000; 
      const increment = end / (duration / 16);

      const animate = () => {
        start += increment;
        if (start < end) {
          incrementRef.current.textContent = `Rs. ${start.toFixed(2)} Bn`;
          requestAnimationFrame(animate);
        } else {
          incrementRef.current.textContent = `Rs. ${end.toFixed(2)} Bn`;
        }
      };

      requestAnimationFrame(animate);
    };



    const incrementObserver1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          incrementNumber1();
          incrementObserver1.unobserve(incrementRef1.current);
        }
      });
    });
    if (incrementRef1.current) {
      incrementObserver1.observe(incrementRef1.current);
    }

    
    const incrementNumber1 = () => {
      let start = 0;
      const end = 1.35; 
      const duration = 2000; 
      const increment = end / (duration / 16);

      const animate1 = () => {
        start += increment;
        if (start < end) {
          incrementRef1.current.textContent = `Rs. ${start.toFixed(2)} Bn`;
          requestAnimationFrame(animate1);
        } else {
          incrementRef1.current.textContent = `Rs. ${end.toFixed(2)} Bn`;
        }
      };

      requestAnimationFrame(animate1);
    };

    // Cleanup observers on unmount
    return () => {
      if (imageCircleRef.current) imageObserver1.unobserve(imageCircleRef.current);
      if (imageCircleRef1.current) imageObserver2.unobserve(imageCircleRef1.current);
      if (incrementRef.current) incrementObserver.unobserve(incrementRef.current);
      if (incrementRef1.current) incrementObserver1.unobserve(incrementRef1.current);
    };
  }, []);



  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="whyInvest-investor-desk">
        <div className="whyInvest-investor-desk1">
          <div className="whyInvest-investor-desk2">
            <div className="whyInvest-investor-desk3">
                  <div className="whyInvest-investor-desk-child" />
                  <div className="whyInvest-investor-desk-item" />
                  <div className="whyInvest-investor-desk-inner" />
                  <div className="whyInvest-rectangle-div" />
                  <div className="whyInvest-business-home-leroux-1-parent">
                    <div className="whyInvest-rectangle-group">
                      <img
                        className="whyInvest-group-child3"
                        alt=""
                        src={InvestorWhyImage5}
                      />
                      <div className="whyInvest-button-parent">
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">
                              Total Value Rs. Million
                            </div>
                            <div className="whyInvest-div">947.70</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Per Share Rs.</div>
                            <div className="whyInvest-div">2.50</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading2">Dividend Information</div>
                    </div>
                    <div className="whyInvest-rectangle-container">
                      <div className="whyInvest-group-child5" />
                      <div className="whyInvest-group-child6" />
                      <div className="whyInvest-button-group">
                        {/* <div className="whyInvest-button6">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div> */}
                         <div className="whyInvest-button6">
                            <a href="" className="whyInvest-button-child" role="button" >
                              <div className="whyInvest-read-more">Read More</div>
                              <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                            </a>
                          </div>
                        <div className="whyInvest-button7">
                          <div className="whyInvest-button-child6" />
                          <div className="whyInvest-cicx0000-parent">
                            <div className="whyInvest-cicx0000">CIC.X0000</div>
                            <img
                              className="whyInvest-group-child8"
                              alt=""
                              src={InvestorWhyImage7}
                            />
                          </div>
                        </div>
                        <div className="whyInvest-button8">
                          <div className="whyInvest-button-child6" />
                          <div className="whyInvest-cicn0000-parent">
                            <div className="whyInvest-cicn0000">CIC.N0000</div>
                            <img
                              className="whyInvest-group-child9"
                              alt=""
                              src={InvestorWhyImage7}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading3">Share Information</div>
                    </div>
                    <div className="whyInvest-rectangle-parent1">
                      <div className="whyInvest-group-child10" />
                      <div className="whyInvest-button-container">
                        {/* <div className="whyInvest-button4">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div> */}
                         <div className="whyInvest-button4">
                            <a href="" className="whyInvest-button-child" role="button" >
                              <div className="whyInvest-read-more">Read More</div>
                              <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                            </a>
                          </div>
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">
                              Total Value Rs. Million
                            </div>
                            <div className="whyInvest-div">947.70</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Per Share Rs.</div>
                            <div className="whyInvest-div">2.50</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading4">Dividend Information</div>
                    </div>
                    <div className="whyInvest-rectangle-parent2">
                      <div className="whyInvest-group-child12" />
                      <div className="whyInvest-button-container">
                        {/* <div className="whyInvest-button4">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div> */}
                         <div className="whyInvest-button4">
                            <a href="" className="whyInvest-button-child" role="button" >
                              <div className="whyInvest-read-more">Read More</div>
                              <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                            </a>
                          </div>
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">Non-Voting Shares</div>
                            <div className="whyInvest-div">5.0 Bn</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Voting Shares</div>
                            <div className="whyInvest-div">22.3 Bn</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading4">Market Capitalization</div>
                    </div>
                    <div className="whyInvest-rectangle-parent3">
                      <div className="whyInvest-group-child14" />
                      {/* <img className="whyInvest-group-child15" alt="" src={InvestorWhyImage8} /> */}
                      {/* <div className="whyInvest-button13">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                      </div> */}
                      <div className="whyInvest-button13">
                        <a href="" className="whyInvest-button-child" role="button" >
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </a>
                      </div>
                      <div className="whyInvest-group-parent3">
                        <div className="whyInvest-profit-in-q4-202324-parent">
                          <div className="whyInvest-profit-in-q4-container">
                            <p className="whyInvest-p">Profit in Q4</p>
                            <p className="whyInvest-p">2023/24</p>
                          </div>
                          <div ref={incrementRef1} className="whyInvest-enrichcic">Rs. 0.00 Bn</div>
                        </div>

                        <div className='whyInvest-img-h2-p1'>
                          <img className="whyInvest-image-circle1" src={InvestorWhyImage11} alt="loading" ref={imageCircleRef1} />
                        </div>
                      </div>
                      <div className="whyInvest-heading41">Key Highlights & Indicators</div>
                    </div>
                    <div className="whyInvest-rectangle-parent4">
                      <div className="whyInvest-group-child16" />
                      {/* <img className="whyInvest-group-child15" alt="" src={InvestorWhyImage8} /> */}
                      {/* <div className="whyInvest-button13">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                      </div> */}
                      <div className="whyInvest-button13">
                        <a href="" className="whyInvest-button-child" role="button" >
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </a>
                      </div>

                      <div className="whyInvest-group-parent3">
                        <div className="whyInvest-profit-in-q4-202324-parent">
                          <div className="whyInvest-profit-in-q4-container">
                            <p className="whyInvest-p">Turnover in Q4</p>
                            <p className="whyInvest-p">2023/24</p>
                          </div>
                          <div ref={incrementRef} className="whyInvest-enrichcic">Rs. 0.00 Bn</div>
                        </div>
                        <div className='whyInvest-img-h2-p'>
                          <img className="whyInvest-image-circle" src={InvestorWhyImage9} alt="loading" ref={imageCircleRef} />
                        </div>
                      </div>
                      <div className="whyInvest-heading4" >{`Key Highlights & Indicators`}</div>
                    </div>
                    <div className="whyInvest-heading8" data-aos="fade-down" data-aos-duration="500">
                      Investor Statistics</div>
                  </div>
                  <div className="whyInvest-button-parent2" data-aos="fade-left" data-aos-duration="700">
                    <div className="whyInvest-cic-holdings-plc">
                      CIC Holdings PLC provides a unique investment opportunity in the
                      Sri Lankan Agri Produce, Health and Personal Care, Livestock
                      Solutions, Industrial Solutions and Crop Solutions markets. Our
                      strength can be attributed directly to its strict adherence to a
                      unitary vision to improve society by nurturing the lives of
                      those we touch. We at CIC believe in sustainable and ethical
                      business practices and adherence to the highest international
                      standards, so that we provide the best possible outcome for our
                      customers, our partners, as well as our investors.
                    </div>
                    <div className="whyInvest-why-invest-in" data-aos="fade-right" data-aos-duration="700">Why Invest in CIC?</div>
                    <img className="whyInvest-group-child18" alt="" src={InvestorWhyImage10} />
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
