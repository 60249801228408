import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './investorContactInfo.css';

export default function InvestorContactInfo() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  
  return (
    <section className="investCont-whyInvest-contact-head">
      <header className="investCont-whyInvest-contact-20">
        <div className="investCont-whyInvest-content3">
          <h1 className="investCont-whyInvest-heading">Contact Information</h1>
        </div>
        <div className="investCont-whyInvest-row1">
          <article className="investCont-whyInvest-content23" data-aos="fade-left" data-aos-duration="500">
            <div className="investCont-whyInvest-contact-info">
              <header className="investCont-whyInvest-content24">
                <h2 className="investCont-whyInvest-heading10">Viraj Manathunga</h2>
                <p className="investCont-whyInvest-text21">Group Chief Strategy Officer</p>
              </header>
              <address className="investCont-whyInvest-link-parent">
                <a href="mailto:viraj@cic.lk" className="investCont-whyInvest-link">viraj@cic.lk</a>
                <p className="investCont-whyInvest-link1">+94 77 725 2906</p>
              </address>
            </div>
          </article>

          <article className="investCont-whyInvest-content23" data-aos="fade-left" data-aos-duration="1000">
            <div className="investCont-whyInvest-contact-info">
              <header className="investCont-whyInvest-content24">
                <h2 className="investCont-whyInvest-heading10">Damodara Wickramanayake</h2>
                <p className="investCont-whyInvest-text21">CFO CIC Capital/ Chemanex PLC</p>
              </header>
              <address className="investCont-whyInvest-link-parent">
                <a href="mailto:damodara@cic.lk" className="investCont-whyInvest-link">damodara@cic.lk</a>
                <p className="investCont-whyInvest-link1">+94 77 137 9947</p>
              </address>
            </div>
          </article>

          <article className="investCont-whyInvest-content23" data-aos="fade-left" data-aos-duration="1500">
            <div className="investCont-whyInvest-contact-info">
              <header className="investCont-whyInvest-content24">
                <h2 className="investCont-whyInvest-heading10">Uvini Dias</h2>
                <p className="investCont-whyInvest-text21">Business Analyst</p>
              </header>
              <address className="investCont-whyInvest-link-parent">
                <a href="mailto:uvini@cic.lk" className="investCont-whyInvest-link">uvini@cic.lk</a>
                <p className="investCont-whyInvest-link1">+94 77 039 4913</p>
              </address>
            </div>
          </article>

          <article className="investCont-whyInvest-content23" data-aos="fade-left" data-aos-duration="2000">
            <div className="investCont-whyInvest-contact-info">
              <header className="investCont-whyInvest-content24">
                <h2 className="investCont-whyInvest-heading10">Erandi Wickramarachchi</h2>
                <p className="investCont-whyInvest-text21">Group Chief Financial Officer/Compliance Officer</p>
              </header>
              <address className="investCont-whyInvest-link-parent">
                <p className="investCont-whyInvest-link1">011 – 2359359 (Ext.201)</p>
              </address>
            </div>
          </article>
        </div>
      </header>
    </section>
  );
}






// import React from 'react';
// import './investorContactInfo.css';

// export default function InvestorContactInfo() {
//   const contacts = [
//     { name: 'John Doe', position: 'Manager', email: 'john@example.com', phone: '123-456-7890' },
//     { name: 'Jane Smith', position: 'Developer', email: 'jane@example.com', phone: '123-456-7891' },
//     { name: 'Mike Johnson', position: 'Designer', email: 'mike@example.com', phone: '123-456-7892' },
//     { name: 'Emily Davis', position: 'Marketer', email: 'emily@example.com', phone: '123-456-7893' },
//   ];

//   return (
//     <div className="contact-info-container">
//       <h2 className="contact-info-heading">Contact Information</h2>
//       <div className="contact-cards">
//         {contacts.map((contact, index) => (
//           <div key={index} className="contact-card">
//             <h3>{contact.name}</h3>
//             <p> {contact.position}</p>
//             <p> {contact.email}</p>
//             <p> {contact.phone}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
