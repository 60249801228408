import React from "react"
import InvestorCategory from "../templates/InvestorDesktop/investorCategory"
import InvestorContactInfo from "../templates/InvestorDesktop/investorContactInfo"
// import Header from "../components/header"
// import Footer from "../components/footer"
import InvestorMainBanner from "../templates/InvestorDesktop/investorMainBanner"
import InvestorWhyInvest from "../templates/InvestorDesktop/investorWhyInvest"


export default function MediaRoom() {
  return (
    <div>
      {/* <Header /> */}
      <InvestorMainBanner />
      <InvestorWhyInvest />
      <InvestorCategory />
      <InvestorContactInfo />
      {/* <Footer /> */}
    </div>
  )
}
